<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />
         <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">         
            <template v-for="(item, key) in list_check_box">  
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                      <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3> 
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                    
                    <!-- Section Detail Start -->
                    
                    <!--information_general start-->
                    <InformationGeneral v-if="key == 'detail_information_general' && item.show" />
                    <!--information_general end-->  

                    <!--Indication start-->
                    <Indication v-else-if="key == 'detail_indication' && item.show"/>
                    <!--Indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show" />
                    <!-- treatment_line end -->

                    <!--AWMSG advice start-->
                    <div class="content-onglets" v-else-if="key == 'detail_awmsg_advice' && item.show">
                        <p v-html="data.awmsg['awmsg_advice']"></p>
                    </div>
                    <!--AWMSG advice end-->

                    <!--Conclusion start-->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />
                    <!--Conclusion end-->

                    <!--background start-->
                    <div class="content-onglets" v-else-if="key == 'detail_background' && item.show">
                        <p v-html="data.awmsg['background']"></p>
                    </div>
                    <!--background end-->

                    <!--comparators start-->
                    <div class="content-onglets" v-else-if="key == 'detail_comparators' && item.show">
                        <p v-html="data.awmsg['comparators']"></p>
                    </div>
                    <!--comparators start-->

                    <!--clinical_trials start-->
                    <div class="content-onglets" v-else-if="key == 'detail_clinical_trials' && item.show">
                        <p v-html="data.awmsg['clinical_trials']"></p>
                    </div>
                    <!--clinical_trials end-->

                    <!-- detail_EvaluationEconomic  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.awmsg.evaluation_economic" />

                    <!-- detail_EconomicEvaluation -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />

                    <!--BudgetImpact start-->
                    <div class="content-onglets" v-else-if="key == 'detail_budget_impact' && item.show"> 
                        <p v-html="data.awmsg['budget_impact']"></p>
                    </div>
                    <!--BudgetImpact end-->

                    <!--network_meta_analysis start-->
                    <div class="content-onglets" v-else-if="key == 'detail_network_meta_analysis' && item.show"> 
                        <p v-html="data.awmsg['network_meta_analysis']"></p>
                    </div>
                    <!--network_meta_analysis end--> 

                    <!--comparative_safety start-->
                    <div class="content-onglets" v-else-if="key == 'detail_comparative_safety' && item.show"> 
                        <p v-html="data.awmsg['comparative_safety']"></p>
                    </div>
                    <!--comparative_safety end-->    

                    <!--cost_effectiveness_evidence start-->
                    <div class="content-onglets" v-else-if="key == 'detail_cost_effectiveness_evidence' && item.show"> 
                        <p v-html="data.awmsg['comparative_safety']"></p>
                    </div>
                    <!--cost_effectiveness_evidence end-->   

                    <!--budget_impact start-->
                    <div class="content-onglets" v-else-if="key == 'show_budget_impact' && item.show">
                        <p v-html="data.awmsg['budget_impact']"></p>
                    </div>
                    <!--budget_impact end-->

                    <!--detail_awttc_conclusion start-->
                    <div class="content-onglets" v-else-if="key == 'detail_awttc_conclusion' && item.show">
                        <p v-html="data.awmsg['conclusion']"></p>
                    </div>
                    <!--detail_awttc_conclusion end-->

                    <!--additional_information start-->
                    <div class="content-onglets" v-else-if="key == 'detail_additional_information' && item.show">
                        <p v-html="data.awmsg['additional_information']"></p>
                    </div>
                    <!--additional_information end-->  

                    <!--assessment_history start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_assessment_history' && item.show">
                        <table class="comparateur2 border-top-gray">
                            <thead>
                                <tr>
                                    <th width="50%" class="first" >Event </th>
                                    <th width="50%" class="last"> Date </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left"> {{$t('assessment_history_ministerial_ratification_date')}} </td>
                                    <td> {{data.awmsg['ministerial_ratification_date'] ? DDMMMYY(data.awmsg['ministerial_ratification_date']) : '-'}} </td>
                                </tr>
                                <tr>
                                    <td class="text-left"> {{$t('assessment_history_awmsg_meeting_date')}} </td>
                                    <td> {{data.awmsg['awmsg_meeting_date'] ? DDMMMYY(data.awmsg['awmsg_meeting_date']) : '-'}} </td>
                                </tr>
                                <tr>
                                    <td class="text-left"> {{$t('assessment_history_nmg_meeting_date')}} </td>
                                    <td> {{data.awmsg['nmg_meeting_date'] ? DDMMMYY(data.awmsg['nmg_meeting_date']) : '-'}} </td>
                                </tr>
                                <tr>
                                    <td class="text-left"> {{$t('assessment_history_date_submission_received')}} </td>
                                    <td> {{data.awmsg['date_submission_received'] ? DDMMMYY(data.awmsg['date_submission_received']) : '-'}} </td>
                                </tr>
                            </tbody>
                            
                        </table>
                    </div>
                    <!--assessment_history end-->  

                    <!--key_documents start-->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    <!--key_documents end-->  

                </div>
            </template>
            
            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
       
        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import InformationGeneral from '../InformationGeneral.vue'
import Indication from '../Indication.vue'
import { DDMMMYY, check_detail_conclusion, key_documents } from '../../../utils'


export default{
    name: 'awmsg',
    components : {
        LeftSection,
        TreatmentLine,
        EconomicEvaluation,
        Conclusion,
        LinkAgency,
        KeyDocument,
        InformationGeneral,
        Indication,
        EvaluationEconomic
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_information_general : { 'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},               
                detail_indication : { 'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false},               
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_awmsg_advice : { 'title' : 'awmsg_advice', 'show' : true, 'enable' : false, 'orange_text':false},               
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_background : {'title' : 'background', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_comparators : {'title' : 'Comparators', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },           
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true }, 
                detail_network_meta_analysis : {'title' : 'network_meta_analysis', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_comparative_safety : {'title' : 'comparative_safety', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_cost_effectiveness_evidence : {'title' : 'cost_effectiveness_evidence', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_budget_impact : {'title' : 'Budget impact', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_awttc_conclusion : {'title' : 'AWTTC conclusion', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_additional_information : {'title' : 'additional_information', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_assessment_history : {'title' : 'assessment_history', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },             
            } ,
            
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.awmsg){
            
            if(this.data['ma_date'])
            { 
                this.list_check_box['detail_information_general'].enable = true
            }
            if(this.data['indication_en'])
            { 
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.data.awmsg['awmsg_advice'])
            { 
                this.list_check_box['detail_awmsg_advice'].enable = true 
            }
            if(this.check_detail_conclusion(this.data, this.$i18n.locale))
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }
            if(this.data.awmsg['background'])
            { 
                this.list_check_box['detail_background'].enable = true 
            } 
            if(this.data.awmsg['comparators'])
            { 
                this.list_check_box['detail_comparators'].enable = true 
            } 

            if(this.data.awmsg['clinical_trials'])
            { 
                this.list_check_box['detail_clinical_trials'].enable = true 
            }

            if(this.data.awmsg['evaluation_economic'].length > 0)
            { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false
            } 

            if(this.data.awmsg['network_meta_analysis'])
            { 
                this.list_check_box['detail_network_meta_analysis'].enable = true 
            } 
            if(this.data.awmsg['comparative_safety'])
            { 
                this.list_check_box['detail_comparative_safety'].enable = true 
            } 
            if(this.data.awmsg['cost_effectiveness_evidence'])
            { 
                this.list_check_box['detail_cost_effectiveness_evidence'].enable = true 
            } 
            if(this.data.awmsg['budget_impact'])
            { 
                this.list_check_box['detail_budget_impact'].enable = true
            }
            if(this.data.awmsg['conclusion'])
            { 
                this.list_check_box['detail_awttc_conclusion'].enable = true
            }
            if(this.data.awmsg['additional_information'])
            { 
                this.list_check_box['detail_additional_information'].enable = true
            }
            if(this.data.awmsg['date_submission_received'] || this.data.awmsg['nmg_meeting_date'] ||
                this.data.awmsg['awmsg_meeting_date'] || this.data.awmsg['ministerial_ratification_date'])
            { 
                this.list_check_box['detail_assessment_history'].enable = true
            }                
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }

            }
            
    },
    methods : {
        DDMMMYY,
        check_detail_conclusion,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }  
    },
}

</script>

<style scoped>
</style>